

































import Vue, { PropType } from 'vue';
import {
    DatePicker as VCalendarDatePicker,
    setupCalendar,
} from 'v-calendar/lib/v-calendar.umd';
import { DateField } from '@/types/forms/fields/dateField';
import { DateRangeField } from '@/types/forms/fields/dateRangeField';
import { Validation } from 'vuelidate';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { germanDateFormat } from '@/utilities/germanFormats';

setupCalendar({
    screens: {
        xl: '1570px',
    },
});

export default Vue.extend({
    name: 'DatePicker',
    components: {
        VCalendarDatePicker,
    },
    props: {
        model: {
            type: Object as PropType<DateField> | PropType<DateRangeField>,
            default: () => {
                return { value: null };
            },
        },
        validations: {
            type: Object as PropType<
                | (Validation &
                      ValidationGroups &
                      ValidationProperties<unknown>)
                | null
            >,
            default: null,
        },
    },
    data: () => {
        return {
            masks: {
                title: 'MMM YYYY',
                navMonths: 'MM',
                weekdays: 'WWW',
            },
            popover: {
                visibility: 'click',
            },
            modelConfig: {
                type: 'string',
                mask: germanDateFormat,
            },
        };
    },
    computed: {
        isRange() {
            return this.model instanceof DateRangeField;
        },
    },
    watch: {
        'model.value': {
            handler(): void {
                if (this.validations) {
                    this.validations.$touch();
                }
            },
            deep: true,
            immediate: false,
        },
    },
    methods: {
        handlePopoverDidHide(): void {
            if (this.validations) {
                this.validations.$touch();
            }
        },
    },
});
