var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCalendarDatePicker',{staticClass:"datepicker",attrs:{"mode":"date","is-range":_vm.isRange,"masks":_vm.masks,"available-dates":{
        start: _vm.model.availableStart,
        end: _vm.model.availableEnd,
    },"popover":_vm.popover,"model-config":_vm.modelConfig,"locale":"de-DE","columns":_vm.$screens({ default: 1, xl: 2 })},on:{"popoverDidHide":function($event){return _vm.handlePopoverDidHide()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var inputEvents = ref.inputEvents;
    var isDragging = ref.isDragging;
return [_vm._t("default",null,null,{ inputValue: inputValue, inputEvents: inputEvents, isDragging: isDragging })]}}],null,true),model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},[_c('a',{staticClass:"left font-bold text-3xl leading-5",attrs:{"slot":"header-left-button"},slot:"header-left-button"}),_c('a',{staticClass:"right font-bold text-3xl leading-5",attrs:{"slot":"header-right-button"},slot:"header-right-button"})])}
var staticRenderFns = []

export { render, staticRenderFns }